import { component } from 'picoapp'
import choozy from 'choozy'
import { add, remove, on } from 'martha'
import gsap from 'gsap'
import bezier from 'bezier-easing'

export default component((node, ctx) => {
  let { items, outer, inner, xbutton, background, burger, patty, topBun, bottomBun, linkButton1, linkButton2, linkButton3 } = choozy(
    node,
    'js:orderReserveModal-',
  )

  const findIsMobile = () => window.innerWidth < 1024
  const findIsVerySmallHeight = () => window.innerHeight < 835
  const findIsSmallHeight = () => window.innerHeight >= 835 && window.innerHeight < 1050
  const findIsMediumHeight = () => window.innerHeight >= 1050 && window.innerHeight < 1200
  const findIsLargeHeight = () => window.innerHeight >= 1200
  
  let isMobile = findIsMobile()
  let isVerySmallHeight = findIsVerySmallHeight()
  let isSmallHeight = findIsSmallHeight()
  let isMediumHeight = findIsMediumHeight()
  let isLargeHeight = findIsLargeHeight()

  const resizedw = () => {
    isMobile = findIsMobile()
    isVerySmallHeight = findIsVerySmallHeight()
    isSmallHeight = findIsSmallHeight()
    isMediumHeight = findIsMediumHeight()
    isLargeHeight = findIsLargeHeight()
  }

  let fireResizeEvent;
  addEventListener('resize', () => {
    clearTimeout(fireResizeEvent);
    fireResizeEvent = setTimeout(resizedw, 100);
  })

  let tl = gsap.timeline({ paused: true, defaults: { duration: 0.7 } })

  const xImg = document.createElement('img')
  xImg.src = ctx.getState().isDayMode ? '/icons/x-icon-invert.png' : '/icons/x-icon.png'
  xbutton.appendChild(xImg)

  let offClick = on(xbutton, 'click', () =>
    ctx.emit('orderReserveModal:toggle', { orderReserveModalIsOpen: false })
  )

  let burgerOffClick = on(burger, 'click', (event) => {
    ctx.emit('orderReserveModal:toggle', { orderReserveModalIsOpen: false })
    event.stopImmediatePropagation()
  })

  let backgroundOffClick = on(background, 'click', () =>
    ctx.emit('orderReserveModal:toggle', { orderReserveModalIsOpen: false })
  )

  let offKeydown = on(document, 'keydown', ({ key }) => {
    if (key !== 'Escape') return
    if (!ctx.getState().orderReserveModalIsOpen) return

    ctx.emit('orderReserveModal:toggle', { orderReserveModalIsOpen: false })
  })


  let linkButton1Click = () => {}
  if (linkButton1) {
    linkButton1Click = on(linkButton1, 'click', () => {
      ctx.emit('orderReserveModal:toggle', { orderReserveModalIsOpen: false })
      return true
    })
  }

  let linkButton2Click = () => {}
  if (linkButton2) {
    linkButton2Click = on(linkButton2, 'click', () => {
      ctx.emit('orderReserveModal:toggle', { orderReserveModalIsOpen: false })
      return true
    })
  }

  let linkButton3Click = () => {}
  if (linkButton3) {
    linkButton3Click = on(linkButton3, 'click', () => {
      ctx.emit('orderReserveModal:toggle', { orderReserveModalIsOpen: false })
      return true
    })
  }

  ctx.on('orderReserveModal:toggle', ({ orderReserveModalIsOpen }) =>
    orderReserveModalIsOpen ? open() : close()
  )

  function open() {
    let { dom, ww, wh } = ctx.getState()
    let cache = getCache({ ww, wh })

    if (!isMobile) background.classList.toggle('hidden')

    let marginTop = 0

    if (!isMobile) {
      if (isVerySmallHeight || isSmallHeight) {
        marginTop = '2%'
      } else if (isMediumHeight) {
        marginTop = '4%'
      } else if (isLargeHeight) {
        marginTop = '10%'
      }
    }

    tl.clear()
      .add(() => add(dom.body, 'overflow-hidden'))
      .to(
        patty,
        {
          scaleX: 0,
          alpha: 0,
          ease: 'expo',
        },
        0,
      )
      .to(
        topBun,
        {
          scaleX: 1,
          y: '2.6rem',
          rotation: 45,
          ease: 'expo',
        },
        0,
      )
      .to(
        bottomBun,
        {
          scaleX: 1,
          y: '-2.6rem',
          rotation: -45,
          ease: 'expo',
        },
        0,
      )
      // open overlay
      .to(
        [outer, inner],
        {
          transform: `translateY(0%)`,
          ease: bezier(0.785, 0.135, 0.15, 0.86),
          marginTop,
        },
        0,
      )
      .set(
        items,
        {
          x: gsap.utils.wrap(cache.map((entry) => entry.x)),
          y: gsap.utils.wrap(cache.map((entry) => entry.y)),
          rotation: gsap.utils.wrap(cache.map((entry) => entry.rotation)),
        },
        0,
      )
      .to(
        items,
        {
          x: 0,
          y: 0,
          rotation: 0,
          stagger: 0.02,
          ease: bezier(0.23, 1, 0.32, 1),
        },
        0.2,
      )
      .restart()
  }

  function close() {
    let { dom } = ctx.getState()

    if (!isMobile) background.classList.toggle('hidden')

    tl.clear()
      .add(() => remove(dom.body, 'overflow-hidden'))
      .to(
        patty,
        {
          scaleX: 1,
          alpha: 1,
          ease: 'expo',
        },
        0,
      )
      .to(
        topBun,
        {
          scaleX: 0.85,
          y: 0,
          rotation: 0,
          ease: 'expo',
        },
        0,
      )
      .to(
        bottomBun,
        {
          scaleX: 0.7,
          y: 0,
          rotation: 0,
          ease: 'expo',
        },
        0,
      )
      // close overlay
      .to(
        [outer, inner],
        {
          transform: gsap.utils.wrap([`translateY(-100%)`, `translateY(100%)`]),
          ease: bezier(0.165, 0.84, 0.44, 0.4),
          duration: !isMobile ? 0.2 : 0.5,
          marginTop: 0,
        },
        0,
      )
      .restart()
  }

  function getCache({ ww, wh }) {
    return items.map((item, i) => {
      return {
        x: 0,
        y: '100%',
        rotation: 0,
      }
    })
  }

  return () => {
    ctx.emit('orderReserveModal:toggle', { orderReserveModalIsOpen: false })
    offClick()
    backgroundOffClick()
    offKeydown()
    burgerOffClick()
    linkButton1Click()
    linkButton2Click()
    linkButton3Click()
  }
})
